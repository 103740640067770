import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import InfoboxTwo from '../components/global/InfoboxTwo';
import Padding from '../components/global/Padding/Padding';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import Customers from '../components/global/customers/Customers';
import FeatureSnippetThree from '../components/global/FeatureSnippetThree';
import Faq from '../components/global/Faq';
import SiriWave from '../../utils/siriwave';
import RealEstateCTA from '../components/global/CTA/RealEstateCTA';
import { setColors } from '../../utils/helpers';
import SEO from '../components/global/seo';
import * as S from '../components/newly-developed-pages/style';

const PropertyManagementChatbot = ({ data }) => {
	const {
		heroHeadingText,
		heroTitle,
		heroSubtitle,
		heroButtonOneText,
		heroButtonOneLink,
		heroButtonTwoLink,
		heroButtonTwoText,
		heroIcon,
		heroImage,
		section1title,
		section1images,
		section2title,
		section2text,
		section3image,
		section3logo,
		section3content,
		section4image,
		section4logo,
		section4content,
		section5backgroundVideo,
		section5foregroundVideo,
		section5mobileImage,
		section5logo,
		section5content,
		section6image,
		section6logo,
		section6content,
		section7image,
		section7logo,
		section7content,
		faqTitle,
		faqSubtitle,
		faqContent,
		section8title,
		section8subtitle,
		section8buttonText,
		section8buttonLink,
		seoTitle,
		seoDescription,
		seoImage,
		seoJsonSchema,
	} = data.contentfulPropertyManagementChatbot;
	useEffect(() => {
		new SiriWave({
			container: document.getElementById('siri-container'),
			width: 640,
			height: 200,
			style: 'ios',
			color: setColors.black,
			frequency: 6,
			amplitude: 1.5,
			curveDefinition: [
				{
					attenuation: -2,
					lineWidth: 1,
					opacity: 0.6,
					color: '229,231,233',
				},
				{
					attenuation: 2,
					lineWidth: 1,
					opacity: 0.8,
					color: '255,159,148',
				},
				{
					attenuation: 1,
					lineWidth: 1.5,
					opacity: 1,
					color: '109,111,189',
				},
			],
		}).setSpeed(0.03);
	}, []);
	return (
		<Layout>
			<S.HeroWrapper>
				<InfoboxTwo
					heading={heroHeadingText}
					title={heroTitle}
					subtitle={heroSubtitle}
					buttonOneText={heroButtonOneText}
					buttonOneLink={heroButtonOneLink}
					buttonTwoText={heroButtonTwoText}
					buttonTwoLink={heroButtonTwoLink}
					image={heroImage}
					isHeroHeader
					icon={heroIcon}
				/>
				<S.SiriWrapper>
					<div id="siri-container"></div>
				</S.SiriWrapper>
			</S.HeroWrapper>
			<S.SectionWrapper>
				<S.CustomerWrapper>
					<Customers
						section2Images={section1images}
						section2Title={section1title}
					/>
				</S.CustomerWrapper>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<Padding padding="100" />
				<S.ContentWrapper>
					<WrapperOne wrapperClass="disable-grid-mobile">
						<div className="section-header">
							<SectionTextBox
								title={section2title}
								richTextContent={section2text}
							/>
						</div>
					</WrapperOne>
				</S.ContentWrapper>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<S.FeatureSnippetThreeHolder>
						<FeatureSnippetThree
							image={section3image}
							content={section3content}
							invert="true"
							logo={section3logo}
						/>
					</S.FeatureSnippetThreeHolder>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<S.FeatureSnippetThreeHolder>
						<FeatureSnippetThree
							image={section4image}
							logo={section4logo}
							content={section4content}
						/>
					</S.FeatureSnippetThreeHolder>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<S.FeatureSnippetThreeHolder>
						<FeatureSnippetThree
							content={section5content}
							invert="true"
							logo={section5logo}
							mediaType="bgVideo"
							bgVideo={section5backgroundVideo}
							fgVideo={section5foregroundVideo}
							mobileImage={section5mobileImage}
						/>
					</S.FeatureSnippetThreeHolder>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<S.FeatureSnippetThreeHolder>
						<FeatureSnippetThree
							image={section6image}
							logo={section6logo}
							content={section6content}
						/>
					</S.FeatureSnippetThreeHolder>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<S.FeatureSnippetThreeHolder>
						<FeatureSnippetThree
							image={section7image}
							logo={section7logo}
							invert="true"
							content={section7content}
						/>
					</S.FeatureSnippetThreeHolder>
				</WrapperOne>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<S.FaqWrapper>
					<S.BGWrapperTwo>
						<Padding padding="100" />
						<Padding padding="100" />
						<WrapperOne disableBottomMargin="true">
							<Faq
								title={faqTitle}
								subtitle={faqSubtitle}
								content={faqContent}
								largeTitle="true"
								primaryColor={setColors.themePurple}
							/>
						</WrapperOne>
						<Padding padding="120" />
					</S.BGWrapperTwo>
				</S.FaqWrapper>
			</S.SectionWrapper>
			<S.SectionWrapper>
				<WrapperOne disableBottomMargin="true">
					<RealEstateCTA
						title={section8title}
						subtitle={section8subtitle}
						buttonText={section8buttonText}
						buttonLink={section8buttonLink}
					/>
				</WrapperOne>
			</S.SectionWrapper>
			<S.GlobalStyle />
			<SEO
				title={seoTitle}
				description={seoDescription}
				article={true}
				image={seoImage}
				seoJsonSchema={seoJsonSchema}
			/>
		</Layout>
	);
};

export default PropertyManagementChatbot;

export const pageQuery = graphql`
	{
		contentfulPropertyManagementChatbot {
			heroHeadingText
			heroTitle
			heroSubtitle
			heroButtonOneText
			heroButtonOneLink
			heroButtonTwoLink
			heroButtonTwoText
			heroIcon {
				fixed(height: 30, width: 128, quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			heroImage {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			section1title
			section1images {
				fluid(quality: 10) {
					...GatsbyContentfulFluid
				}
				title
			}
			section2title
			section2text {
				raw
			}
			section3image {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			section3logo {
				file {
					details {
						image {
							height
							width
						}
					}
				}
				fixed(quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			section3content {
				title
				subtitle
				linkText
				link
			}
			section4image {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			section4logo {
				file {
					details {
						image {
							height
							width
						}
					}
				}
				fixed(quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			section4content {
				title
				subtitle
				linkText
				link
			}
			section5backgroundVideo {
				file {
					url
					contentType
				}
			}
			section5foregroundVideo {
				file {
					url
					contentType
				}
			}
			section5mobileImage {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			section5logo {
				fixed {
					src
				}
				file {
					details {
						image {
							width
							height
						}
					}
				}
				title
			}
			section5content {
				title
				subtitle
				linkText
				link
			}
			section6image {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			section6logo {
				file {
					details {
						image {
							height
							width
						}
					}
				}
				fixed(quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			section6content {
				title
				subtitle
				linkText
				link
			}
			section7image {
				fluid(quality: 70) {
					...GatsbyContentfulFluid
				}
				title
			}
			section7logo {
				file {
					details {
						image {
							height
							width
						}
					}
				}
				fixed(quality: 30) {
					...GatsbyContentfulFixed
				}
				title
			}
			section7content {
				title
				subtitle
				linkText
				link
			}
			faqTitle
			faqSubtitle {
				raw
			}
			faqContent {
				question
				answer {
					type
					content
				}
			}
			section8title
			section8subtitle {
				raw
			}
			section8buttonText
			section8buttonLink
			seoTitle
			seoDescription
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoJsonSchema {
				internal {
					content
				}
			}
		}
	}
`;
